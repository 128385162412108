<template>
  <div class="contactus container-fluid min-vh-100">
    <vue-headful
      title="Install-HC - Nous contacter" 
      description="Contacter les administrateurs du site Install-HC"
    />
    <div>
      <nav role="navigation">
        <div id=header class="float-right">
          <InstallHC/>
        </div> 
        <Menu/>
      </nav>
    </div>
    <div>
      <h1>Nous contacter</h1>            
      <div v-if="error === true" class="red">
        Erreur recontrée !
      </div>      
      <div v-if="error === false" class="blue">
        Message envoyé avec succès !
      </div>    
      <div>
        <p>
          Un problème de connexion, de mise à jour de votre Installation Home Cinema, un bug rencontré sur une page ou des suggestions à nous proposer... N'hésitez pas à nous laisser un message via ce formulaire.
        </p>
      </div>        
      <form v-on:submit.prevent="checkMsg" class="whitebg contentForm">
        <p>Votre nom : <input v-model="nom" minlength="4" maxlength="128" type="text" size="64" :run="checkUser()" :disabled="disabled"/></p>
        <p v-if="disabled === false" >Votre adresse e-mail : <input v-model="email" minlength="4" maxlength="128" type="email"  size="64"/> </p>
        <p>Votre message :<br/>
        <textarea v-model="message"/>
        </p>
        <div class="divButton"> 
          <button @click="checkMsg" :disabled="stopped" class="shadow button">Envoyer</button>
        </div>
      </form>
    </div>    
    <Pub/>
  </div>  
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu.vue'
import InstallHC from '@/components/InstallHC.vue'
export default { 
  name: 'contactus',
  components: {
    Menu,
    InstallHC
  },
  data() {         
    return {
      json: null, 
      windowHeight: 0,
      txt: '',
      actualSize: 0,
      target: '',
      nom: '',
      email: '',
      message: '',
      error: null,
      disabled: false,
      stopped: false
    }    
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },    
  mounted() {    
    this.actualSize = window.innerHeight    
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowHeight = window.innerHeight;
        this.actualSize = (window.innerHeight-117)
      })
    })   
  },
  computed: {    
    getNom: function() {
      return localStorage.getItem('login')
    },
    cleanProprio: function() {
      return this.proprio.replace(/___/gi,' ')
    }    
  },
  methods: {
    checkUser: function() {      
      if(this.getNom !== null) {
        this.nom = this.getNom
        this.disabled = true
      }
    },
    checkMsg: function(e) {      
      this.error = null
      if(this.message.trim() != '' && this.nom != '') {
        if( this.disabled === true || this.email.trim() != '') {
          this.postMsg()  
        }
        else {
          this.error = true
        }
      } 
      else {
        this.error = true
      }
      e.preventDefault()
    },
    postMsg: function() {
      var apiUrl = "/backend/get/form/contact"
      fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({nom: this.nom,email: this.email, message: this.message})
        })
        .then(res => res.json())
        .then(res => {
          if(res.retour != "ok") {
            this.error = true                        
          }
          else if(res.retour === "ok") {            
            this.error = false
            this.message = null
            this.stopped = true
          }                       
        });
    }  
  }
}

</script>

<style scoped>
.debug {
  display: none;
}

.contactus {
  margin:0 auto;  
  width:100%;    
  padding:0;
}

h1 {
  font-size:1.05em;
  font-weight:bold;
  text-align: center;
  margin-top:10px;
}

a {
  font-weight: bold;
  font-size:1.1rem;
}

h2 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAA6NJREFUeJzt20uIHEUcx/HPDkFQ9+JBBMNG4s1LVFBR2AUhGgx4EB+gZ0EQUVRUEALGPBCEaCRiQEGPelAEUYOoJ0F0FTV68iYrWQIKZqNEEZN4qAzUzvT0a7q7eh8/KNidqvpXfX/VU91VXcOmNrZmcvLmcC1mO+pLrE/wR8U6O7AdJ/Az/qzb+Bw+xPlEab/8gcnSAv6KYpzDcRzA1VXhlxPC72sAfjSdxRFcUiZYypFvAz5OX+DivGBzCeGfrwE/g7cqtnM4L+Cdawg+1l34rWRb/+GqSYHuTwC/dwrwWFcKl3iZNp+bFKRrA/bWAJ3HAxhk5F2EN0u0+21caUuFxn/Fl5W7nK2v8XLFOgs4hkvxFB7GYpT/Lx7ESTybE+d6YTL8ezSj6Ap4p2KHm1TWbH8WB2UP4n75LDuyGumrAUW3us9x2UidGRzNqbM7q6E+GlD2Pv8TrhipuwWfZpRdwTVZjfXNgCoPOUMTRq+Ey4W1QQx/86QG+2RAVfhh+sz4nHCbEvD0x4C68MN0ICPmIQXw9MOAaeGHd4cbyzaY9UCRSvF9fhoN8JqSbH0xoCn4oW7AfWUK9sGApuHhNH4pUzC1AW3B7xIetwuV0oDk8KQzoBfwpDGgN/B0b0Cv4OnWgLbgb1cNfiBaOHVlQJvwi0UFIw2EZfJC/EHb6gv8DF7FQ/GHbRvQJ/gjwjbaKrVpQJ/gD+ORrMy2DGgDfkU9+EN4bFKBNgxoC36X6vAv4om8Qm0Y8Du+azBeXfgXhO3z0mpyQ2SAJ4W9+mk2N07hpioQF/RMQdx74462oXN4SXiTc6JmjDojP1Rp05owYF7o5N3GX3IuXujM8Yoxp4GvpCYMOCbswb0n7MNvH8lfxq34qmS8OrN9bTVhQHyGaCd+FOaTWKdwB34oiDWE/6aBfpVSG3PALN42ftZnRXgltTShXufwtPskuAevWG3CSeFAwz8jZZPA0/5a4FHh7E+s7/F49H8yeLpZDe4xPie8jo8lhqe7/YA3rD6vd15YmSWFpzsDZoVRj+eDJYnh6XZLbKcwAfZKXW+KPt1xe4Xq0oDTwgKpV6pySmySpjnkmFyp3w0m16YBqTuQWhvegCqT4DzebasjDeuWsgWrGLAV91TvS7+14b8CsQGja/T1rDPDP2IDirar1pMmsn5kun38tZDez3Nmm9WHi9dbWhJ+XpOrOXzQg862MfJj8HkLma24TsFv7daAzgjf+eXUHdlUH/U/+ggB0rZwsxwAAAAASUVORK5CYII=) no-repeat;
  background-size: 20px;
  padding-left: 25px;
  font-size: 1.1rem;
  font-weight: bold;  
}

p {
  margin-left:20px;
  margin-bottom:5px;
}

textarea {
  width:100%;
  height:20em;
}

.button {
  color: #007bff;
  border: none;
  background-color: #eee;
  padding: 10px 32px;
  margin-bottom:5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}

.button:disabled {
  color: #ccc;
}

.divButton {
  text-align:right;
}

.contentForm {
  margin:10px;
  padding:10px;
  border:1px solid #ddd;
  border-radius: 5px;
  text-align: left;
}

.red{
  color:red;
}

.blue {
  color: #007bff;
}

input {
  width:100%;
}

</style>